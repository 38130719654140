<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
            >
                <el-card
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>订单信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="订单编号:"
                                label-width="8em"
                            >
                                {{ order.orderSn }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="下单时间:"
                                label-width="8em"
                            >
                                {{ order.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="订单来源:"
                                label-width="8em"
                            >
                                {{ order.orderSourceName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="商品类型:"
                                label-width="8em"
                            >
                                {{ order.goodsTypeName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="订单状态:"
                                label-width="8em"
                            >
                                {{ order.orderStatusName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="买家留言:"
                                label-width="8em"
                            >
                                {{ order.buyerMsg }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>付款信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="付款模式:"
                                label-width="8em"
                            >
                                {{ order.paymentModeName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="付款时间:"
                                label-width="8em"
                            >
                                {{ order.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="付款类型:"
                                label-width="8em"
                            >
                                {{ order.paymentTypeName }}<span v-if="order.paymentComments">/{{ order.paymentComments }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="付款单号:"
                                label-width="8em"
                            >
                                {{ order.externalPaymentSn }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>商品信息</span>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="order.orderDetailList"
                                style="width: 100%"
                                show-summary
                                :summary-method="getSummaries1"
                            >
                                <el-table-column
                                    label="商品图片"
                                    width="120"
                                >
                                    <template slot-scope="scope">
                                        <ImageList
                                            :data="scope.row.specImageUrl"
                                        />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="goodsName"
                                    label="商品名称"
                                    min-width="100"
                                >
                                    <template slot-scope="scope">
                                        {{ scope.row.goodsName }}
                                        <div
                                            class="goods-wrap body badge-wrap"
                                        >
                                            <div
                                                class="badge"
                                                v-if="order.isPresellOrder"
                                            >
                                                预
                                            </div>
                                            <div
                                                class="badge"
                                                v-if="scope.row.goodsPremiumAmount"
                                            >
                                                保
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="specDesc"
                                    label="商品规格"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="finalPrice"
                                    label="商品价格"
                                    min-width="50"
                                />
                                <el-table-column
                                    prop="quantity"
                                    label="数量"
                                    min-width="50"
                                />
                                <el-table-column
                                    prop="subtotalAmount"
                                    label="小计"
                                    min-width="50"
                                />
                            </el-table>
                        </el-form-item>
                        <el-form-item
                            label="商品总金额:"
                            label-width="8em"
                        >
                            {{ order.goodsAmount }}
                        </el-form-item>
                        <el-form-item
                            label="总运费:"
                            label-width="8em"
                        >
                            {{ order.freight }}
                        </el-form-item>
                        <el-form-item
                            label="优惠券金额:"
                            label-width="8em"
                        >
                            {{ order.couponAmount }}
                        </el-form-item>
                        <el-form-item
                            label="积分抵扣金额:"
                            label-width="8em"
                        >
                            {{ order.pointAmount }}
                        </el-form-item>
                        <el-form-item
                            label="满额减免金额:"
                            label-width="8em"
                        >
                            {{ order.reduceAmount }}
                        </el-form-item>
                        <el-form-item
                            label="总优惠金额:"
                            label-width="8em"
                        >
                            {{ order.totalDiscountAmount }}
                        </el-form-item>
                        <el-form-item
                            label="保费金额:"
                            label-width="8em"
                        >
                            {{ order.goodsPremiumAmount }}
                        </el-form-item>
                        <el-form-item
                            label="手续费:"
                            label-width="8em"
                        >
                            {{ order.paymentPoundage }}
                        </el-form-item>
                        <el-form-item
                            label="订单金额:"
                            label-width="8em"
                        >
                            {{ order.payableAmount }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="order.extendFieldInfoList && order.extendFieldInfoList.length != 0"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>扩展信息</span>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="order.extendFieldInfoList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="label"
                                    label="字段名称"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="value"
                                    label="字段值"
                                    min-width="100"
                                />
                            </el-table>
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="order.shipMethod === 1 && order.goodsType === 1"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <div class="dp-fx fx-row fx-jc-sb fx-ai-c">
                            <span>送货上门</span>
                            <div>
                                <el-button
                                    size="mini"
                                    type="success"
                                    icon="el-icon-edit"
                                    @click="editDeliveryInfo"
                                    v-if="order.orderStatus === 20 || (order.orderStatus === 30 && order.stockHandleStatus !== 3)"
                                >
                                    修改收货信息
                                </el-button>
                                <el-button
                                    size="mini"
                                    type="success"
                                    icon="el-icon-edit"
                                    @click="checkIdCard"
                                    v-if="order.goodsSendMode === 2"
                                >
                                    身份认证
                                </el-button>
                                <el-button
                                    size="mini"
                                    type="success"
                                    icon="el-icon-edit"
                                    @click="editSubscriber"
                                    v-if="order.goodsSendMode === 4"
                                >
                                    修改购买人信息
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="收货人姓名:"
                                label-width="8em"
                            >
                                {{ order.deliveryConsignee }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="收货人电话:"
                                label-width="8em"
                            >
                                {{ order.deliveryPhone }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="收货人地址:"
                                label-width="8em"
                            >
                                {{ order.deliveryRegionName }} {{ order.deliveryAddress }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="order.goodsSendMode != 1 && order.goodsSendMode != 4"
                        >
                            <el-form-item
                                label="身份证号:"
                                label-width="8em"
                            >
                                {{ order.deliveryIdCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="order.goodsSendMode == 2"
                        >
                            <el-form-item
                                label="身份证正面:"
                                label-width="8em"
                            >
                                <ImageList
                                    v-if="order.deliveryIdCardFrontUrl"
                                    :data="order.deliveryIdCardFrontUrl || ''"
                                />
                                <template
                                    v-if="!order.deliveryIdCardFrontUrl"
                                >
                                    未上传
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="order.goodsSendMode == 2"
                        >
                            <el-form-item
                                label="身份证反面:"
                                label-width="8em"
                            >
                                <ImageList
                                    v-if="order.deliveryIdCardBackUrl"
                                    :data="order.deliveryIdCardBackUrl || ''"
                                />
                                <template
                                    v-if="!order.deliveryIdCardBackUrl"
                                >
                                    未上传
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="order.goodsSendMode == 4"
                        >
                            <el-form-item
                                label="付款人姓名:"
                                label-width="8em"
                            >
                                {{ order.subscriberName }}
                            </el-form-item>
                            <el-form-item
                                label="付款人身份证号:"
                                label-width="8em"
                            >
                                {{ order.subscriberIdCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="配送时间:"
                                label-width="8em"
                            >
                                {{ order.shippingTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="收货时间:"
                                label-width="8em"
                            >
                                {{ order.receiveTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="order.shipMethod === 2"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>门店自取</span>
                    </div>
                    <div class="item">
                        <el-col :span="24">
                            <el-form-item
                                label="门店名称:"
                                label-width="8em"
                            >
                                {{ order.pickupStoreName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="门店地址:"
                                label-width="8em"
                            >
                                {{ order.pickupStoreRegionName }} {{ order.pickupStoreName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="自取日期:"
                                label-width="8em"
                            >
                                {{ order.pickupDate | moment('YYYY-MM-DD') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="自取时间:"
                                label-width="8em"
                            >
                                {{ order.pickupTime }}
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="order.shipMethod === 4 && order.goodsType === 1"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <div class="dp-fx fx-row fx-jc-sb fx-ai-c">
                            <span>本地配送</span>
                            <div>
                                <el-button
                                    size="mini"
                                    type="success"
                                    icon="el-icon-edit"
                                    @click="editDeliveryInfo"
                                    v-if="order.orderStatus === 20"
                                >
                                    修改收货信息
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="收货人姓名:"
                                label-width="8em"
                            >
                                {{ order.deliveryConsignee }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="收货人电话:"
                                label-width="8em"
                            >
                                {{ order.deliveryPhone }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="收货人地址:"
                                label-width="8em"
                            >
                                {{ order.deliveryRegionName }} {{ order.deliveryAddress }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="order.goodsSendMode != 1 && order.goodsSendMode != 4"
                        >
                            <el-form-item
                                label="身份证号:"
                                label-width="8em"
                            >
                                {{ order.deliveryIdCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="order.goodsSendMode == 2"
                        >
                            <el-form-item
                                label="身份证正面:"
                                label-width="8em"
                            >
                                <ImageList
                                    v-if="order.deliveryIdCardFrontUrl"
                                    :data="order.deliveryIdCardFrontUrl || ''"
                                />
                                <template
                                    v-if="!order.deliveryIdCardFrontUrl"
                                >
                                    未上传
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="12"
                            v-if="order.goodsSendMode == 2"
                        >
                            <el-form-item
                                label="身份证反面:"
                                label-width="8em"
                            >
                                <ImageList
                                    v-if="order.deliveryIdCardBackUrl"
                                    :data="order.deliveryIdCardBackUrl || ''"
                                />
                                <template
                                    v-if="!order.deliveryIdCardBackUrl"
                                >
                                    未上传
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col
                            :span="24"
                            v-if="order.goodsSendMode == 4"
                        >
                            <el-form-item
                                label="付款人姓名:"
                                label-width="8em"
                            >
                                {{ order.subscriberName }}
                            </el-form-item>
                            <el-form-item
                                label="付款人身份证号:"
                                label-width="8em"
                            >
                                {{ order.subscriberIdCardNo }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-col :span="12">
                                <el-form-item
                                    label="预计配送时间:"
                                    label-width="8em"
                                >
                                    {{ order.confirmShipDate | moment }} {{ order.confirmShipTime }}
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    label="配送员:"
                                    label-width="8em"
                                >
                                    {{ order.driverName }} {{ order.driverMobile }}
                                </el-form-item>
                            </el-col>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="配送时间:"
                                label-width="8em"
                            >
                                {{ order.shippingTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="收货时间:"
                                label-width="8em"
                            >
                                {{ order.receiveTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="收货备注:"
                                label-width="8em"
                            >
                                {{ order.receiveRemarks }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="收货附件:"
                                label-width="8em"
                            >
                                <ImageList
                                    :data="order.receiveAttachmentList"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="(order.shipMethod === 1) && ((order.orderStatus === 30 && order.stockHandleStatus === 3) || order.orderStatus === 100) && orderTrackingList"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>物流信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="快递公司名称:"
                                label-width="8em"
                            >
                                {{ order.expressName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="快递公司代码:"
                                label-width="8em"
                            >
                                {{ order.expressCode }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="物流信息:"
                                label-width="8em"
                            >
                                <el-collapse
                                    v-model="orderTrackingSelect"
                                >
                                    <el-collapse-item
                                        v-for="item in orderTrackingList"
                                        :title="'物流单号：' + item.trackingNumber"
                                        :name="item.id"
                                        :key="item.id"
                                    >
                                        <template v-for="(item2,index) in item.allTrackingInfoList">
                                            <div :key="index">
                                                {{ item2.ftime }} {{ item2.context }}
                                            </div>
                                        </template>
                                    </el-collapse-item>
                                </el-collapse>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="验货图片:"
                                label-width="8em"
                            >
                                <upload
                                    action="/rs/attachment/uploadExamineImage"
                                    v-model="examineImageList"
                                />
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="onSaveExamineImage()"
                                >
                                    保存验货图片
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="order.isPrivateCustom"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>商品定制</span>
                    </div>
                    <div class="item">
                        <el-col :span="24">
                            <el-form-item
                                label="商品定制图片:"
                                label-width="8em"
                            >
                                <ImageList
                                    :data="customGoodsImageList"
                                />
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="order.orderAuditStatus === 20"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>订单审核</span>
                    </div>
                    <div class="item">
                        <el-form
                            ref="addForm"
                            size="small"
                            :model="formModel"
                            :rules="formRules"
                        >
                            <el-form-item
                                prop="orderAuditStatus"
                                label="审核是否通过:"
                                label-width="8em"
                            >
                                <el-select
                                    v-model="formModel.orderAuditStatus"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        :key="30"
                                        label="审核不通过"
                                        :value="30"
                                    />
                                    <el-option
                                        :key="100"
                                        label="审核通过"
                                        :value="100"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                prop="auditOpinion"
                                label="不通过原因:"
                                label-width="8em"
                                v-if="formModel.orderAuditStatus === 30"
                            >
                                <el-input
                                    type="textarea"
                                    v-model="formModel.auditOpinion"
                                    auto-complete="off"
                                />
                            </el-form-item>
                        </el-form>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>订单日志</span>
                    </div>
                    <div class="item">
                        <el-form-item>
                            <!-- 表格 -->
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="orderLogList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    prop="operateTime"
                                    label="操作时间"
                                    min-width="100"
                                >
                                    <template slot-scope="scope">
                                        {{ scope.row.operateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="operatorName"
                                    label="操作人"
                                    min-width="100"
                                />
                                <el-table-column
                                    prop="operateDesc"
                                    label="操作描述"
                                    min-width="100"
                                />
                            </el-table>
                        </el-form-item>
                    </div>
                </el-card>
            </el-form>
            <div class="pa-t">
                <el-button
                    v-if="order.orderAuditStatus === 20"
                    type="primary"
                    size="small"
                    @click="onConfirm()"
                >
                    提交
                </el-button>
                <el-button
                    size="small"
                    type="primary"
                    @click="$router.back()"
                >
                    返 回
                </el-button>
            </div>
        </template>

        <!-- 修改收件信息 -->
        <el-dialog
            title="修改收货信息"
            center
            width="600px"
            :visible.sync="deliveryDialog.isVisible"
            @closed="onDeliveryDialogCancel"
        >
            <el-form
                ref="deliveryForm"
                size="small"
                :model="deliveryDialog.formModel"
                :rules="deliveryDialog.formRules"
            >
                <el-form-item
                    prop="deliveryConsignee"
                    label="收货人"
                    label-width="8em"
                >
                    <el-input
                        v-model="deliveryDialog.formModel.deliveryConsignee"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryPhone"
                    label="收货人电话"
                    label-width="8em"
                >
                    <el-input
                        v-model="deliveryDialog.formModel.deliveryPhone"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryRegionId"
                    label="收货地址"
                    label-width="8em"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="deliveryDialog.formModel.deliveryRegionId"
                        :p-id="2"
                        :min-lv="2"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryAddress"
                    label="详细地址"
                    label-width="8em"
                >
                    <el-input
                        v-model="deliveryDialog.formModel.deliveryAddress"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryIdCardNo"
                    label="身份证号"
                    label-width="8em"
                    v-if="order.goodsSendMode != 1"
                >
                    <el-input
                        v-model="deliveryDialog.formModel.deliveryIdCardNo"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onDeliveryDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onDeliveryDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!-- 修改购买人信息-->
        <el-dialog
            title="修改购买人信息"
            center
            width="600px"
            :visible.sync="editSubscriberDialog.isVisible"
            @closed="onEditSubscriberDialogCancel"
        >
            <el-form
                ref="editSubscriberForm"
                size="small"
                :model="editSubscriberDialog.formModel"
                :rules="editSubscriberDialog.formRules"
            >
                <el-form-item
                    prop="subscriberName"
                    label="购买人姓名"
                    label-width="8em"
                >
                    <el-input
                        v-model="editSubscriberDialog.formModel.subscriberName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="subscriberIdCardNo"
                    label="购买人身份证号"
                    label-width="8em"
                >
                    <el-input
                        v-model="editSubscriberDialog.formModel.subscriberIdCardNo"
                        auto-complete="off"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onEditSubscriberDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onEditSubscriberDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!-- 身份认证-->
        <el-dialog
            title="身份认证"
            center
            width="600px"
            :visible.sync="checkIdCardDialog.isVisible"
            @closed="onCheckIdCardDialogCancel"
        >
            <el-form
                ref="checkIdCardForm"
                size="small"
                :model="checkIdCardDialog.formModel"
                :rules="checkIdCardDialog.formRules"
            >
                <el-form-item
                    prop="deliveryConsignee"
                    label="姓名"
                    label-width="8em"
                >
                    {{ checkIdCardDialog.formModel.deliveryConsignee }}
                </el-form-item>
                <el-form-item
                    prop="deliveryIdCardNo"
                    label="身份证号"
                    label-width="8em"
                >
                    {{ checkIdCardDialog.formModel.deliveryIdCardNo }}
                </el-form-item>
                <el-form-item
                    prop="deliveryIdCardFront"
                    label="身份证正面"
                    label-width="8em"
                >
                    <upload
                        action="/rs/attachment/uploadShopIdCardImage"
                        :limit="1"
                        v-model="checkIdCardDialog.formModel.deliveryIdCardFront"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryIdCardBack"
                    label="身份证背面"
                    label-width="8em"
                >
                    <upload
                        action="/rs/attachment/uploadShopIdCardImage"
                        :limit="1"
                        v-model="checkIdCardDialog.formModel.deliveryIdCardBack"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onCheckIdCardDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onCheckIdCardDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'OrderDetail',
    mixins: [pagesMixin],
    data() {
        return {
            id: '',
            order: {},
            orderLogList: [],
            orderTrackingList: [],
            examineImageList: [],
            // 订单发货
            deliveryDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    deliveryConsignee: '',
                    deliveryPhone: '',
                    deliveryRegionId: '',
                    deliveryAddress: '',
                    deliveryIdCardNo: '',
                },
                // 表单校验规则
                formRules: {
                    deliveryRegionId: {
                        required: true,
                        message: '请选择收货地址',
                        trigger: 'blur',
                    },
                    deliveryConsignee: {
                        required: true,
                        message: '请输入收货人',
                        trigger: 'blur',
                    },
                    deliveryPhone: {
                        required: true,
                        message: '请输入收货人电话',
                        trigger: 'blur',
                    },
                    deliveryAddress: {
                        required: true,
                        message: '请输入详细地址',
                        trigger: 'blur',
                    },
                },
            },
            selectApi: this.$api.Rs.Region,
            orderTrackingSelect: '',
            checkIdCardDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    deliveryConsignee: '',
                    deliveryIdCardNo: '',
                    deliveryIdCardFront: [],
                    deliveryIdCardBack: [],
                },
                // 表单校验规则
                formRules: {
                    deliveryIdCardFront: {
                        type: 'array',
                        required: true,
                        message: '请上传身份证正面',
                    },
                    deliveryIdCardBack: {
                        type: 'array',
                        required: true,
                        message: '请上传身份证反面',
                    },
                },
            },
            editSubscriberDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    subscriberName: '',
                    subscriberIdCardNo: '',
                },
                // 表单校验规则
                formRules: {
                    subscriberName: {
                        required: true,
                        message: '请填写购买人姓名',
                    },
                    subscriberIdCardNo: {
                        required: true,
                        message: '请填写购买人身份证号',
                    },
                },
            },
            formModel: {
                id: '',
                orderAuditStatus: '',
                auditOpinion: '',
            },
            formRules: {
                orderAuditStatus: {
                    required: true,
                    message: '请选择审核是否通过',
                },
                auditOpinion: {
                    required: true,
                    message: '请输入不通过原因',
                    trigger: 'blur',
                },
            },
        };
    },
    methods: {
        init() {
            this.$api.Ex.Order.getDetail({ id: this.id }).then(json => {
                const res = json.data.data;
                this.order = res.order || {};
                this.orderLogList = res.orderLogList || [];
                this.orderTrackingList = res.orderTrackingList || null;
                this.examineImageList = res.examineImageList || [];

                const imageList = [];
                this.examineImageList.forEach(item => {
                    imageList.push({ name: item, address: res.fileBasePath + item });
                });
                this.examineImageList = imageList;
            });
        },
        //订单发货
        editDeliveryInfo() {
            this.$utils.Common.formModelMerge(this.deliveryDialog.formModel, this.order);
            this.deliveryDialog.isVisible = true;
        },
        onDeliveryDialogConfirm() {
            const { deliveryForm } = this.$refs;
            deliveryForm.validate().then(() => {
                this.$api.Ex.Order.editOrderDeliveryInfo({
                    ...this.deliveryDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                    this.deliveryDialog.isVisible = false;
                });
            });
        },
        onDeliveryDialogCancel() {
            const { deliveryForm } = this.$refs;
            deliveryForm.resetFields();
            const { formModel } = this.deliveryDialog;
            formModel.id = '';
            this.deliveryDialog.isVisible = false;
        },
        //身份认证
        checkIdCard() {
            this.checkIdCardDialog.formModel.id = this.order.id;
            this.checkIdCardDialog.formModel.deliveryConsignee = this.order.deliveryConsignee;
            this.checkIdCardDialog.formModel.deliveryIdCardNo = this.order.deliveryIdCardNo;
            if (!this.checkIdCardDialog.formModel.deliveryIdCardNo) {
                this.$message({
                    message: '身份证号未设置',
                    type: 'error',
                });
                return;
            }
            this.checkIdCardDialog.isVisible = true;
        },
        onCheckIdCardDialogConfirm() {
            const { checkIdCardForm } = this.$refs;
            checkIdCardForm.validate().then(() => {
                this.$api.Ex.Order.checkIdCard({
                    ...this.checkIdCardDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                    this.checkIdCardDialog.isVisible = false;
                });
            });
        },
        onCheckIdCardDialogCancel() {
            const { checkIdCardForm } = this.$refs;
            checkIdCardForm.resetFields();
            const { formModel } = this.checkIdCardDialog;
            formModel.id = '';
            this.checkIdCardDialog.isVisible = false;
        },
        //购买人信息
        editSubscriber() {
            this.editSubscriberDialog.formModel.id = this.order.id;
            this.editSubscriberDialog.formModel.subscriberName = this.order.subscriberName;
            this.editSubscriberDialog.formModel.subscriberIdCardNo = this.order.subscriberIdCardNo;
            this.editSubscriberDialog.isVisible = true;
        },
        onEditSubscriberDialogConfirm() {
            const { editSubscriberForm } = this.$refs;
            editSubscriberForm.validate().then(() => {
                this.$api.Ex.Order.editSubscriber({
                    ...this.editSubscriberDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                    this.editSubscriberDialog.isVisible = false;
                });
            });
        },
        onEditSubscriberDialogCancel() {
            const { editSubscriberForm } = this.$refs;
            editSubscriberForm.resetFields();
            const { formModel } = this.editSubscriberDialog;
            formModel.id = '';
            this.editSubscriberDialog.isVisible = false;
        },
        //订单审核
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm('是否确定提交！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.$api.Ex.Order.auditOrder({
                        ...this.formModel,
                        ids: this.id,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.$router.back();
                    });
                });
            });
        },
        getSummaries1(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                if (index === 4 || index === 5) {
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    }
                }
            });
            return sums;
        },
        onSaveExamineImage() {
            this.$confirm('是否确定要修改验货图片！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.Order.saveExamineImage({ id: this.id, examineImage: this.examineImageList }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                });
            });
        },
    },
    computed: {
        customGoodsImageList() {
            const list = [];
            this.order.orderDetailList.forEach(item => {
                for (let key in item.customGoodsImage) {
                    list.push(item.customGoodsImage[key]);
                }
            });
            return list;
        },
    },
    created() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss" scoped>
.badge {
    display: inline-block;
    border-radius: 100em;
    background-color: $color-danger;
    color: #fff;
    line-height: 24px;
    min-width: 24px;
    text-align: center;
}
</style>
