<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.orderStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="全部"
                    name="0"
                />
                <el-tab-pane
                    label="待发货"
                    name="20"
                />
                <el-tab-pane
                    label="仓库中"
                    name="30-1"
                />
                <el-tab-pane
                    label="已发货"
                    name="30-2"
                />
                <el-tab-pane
                    label="已完成"
                    name="100"
                />
                <el-tab-pane
                    label="已取消"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="orderSn"
                    label="订单编号"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        placeholder="请输入订单编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryConsignee"
                    label="收件人"
                >
                    <el-input
                        v-model="queryFormModel.deliveryConsignee"
                        placeholder="请输入收件人"
                    />
                </el-form-item>
                <el-form-item
                    prop="deliveryPhone"
                    label="收件人电话"
                >
                    <el-input
                        v-model="queryFormModel.deliveryPhone"
                        placeholder="请输入收件人电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="buyerLevelId"
                    label="会员等级"
                >
                    <el-select
                        v-model="queryFormModel.buyerLevelId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in buyerLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="orderTime"
                    label="下单时间"
                >
                    <el-date-picker
                        :picker-options="$utils.CommonConfig.pickerOptions"
                        v-model="queryFormModel.orderTime"
                        type="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                    />
                </el-form-item>
                <el-form-item
                    prop="trackingNumber"
                    label="快递单号"
                >
                    <el-input
                        v-model="queryFormModel.trackingNumber"
                        placeholder="请输入快递单号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!--订单列表-->
            <el-table
                class="order-table"
                ref="table"
                stripe
                border
                size="small"
                :data="tableData"
                :span-method="onSpanMethod"
                style="width: 100%"
            >
                <el-table-column
                    class-name="cell-no-pa"
                    label="商品"
                >
                    <template slot-scope="order">
                        <div class="order-header">
                            <div>
                                <span>下单时间：{{ order.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}</span>
                                <span>订单号：{{ order.row.orderSn }}</span>
                            </div>
                        </div>
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="order.row.orderDetailList"
                            style="width: 100%"
                            :show-header="false"
                            :span-method="onGoodsSpanMethod"
                        >
                            <el-table-column label="商品">
                                <div
                                    class="goods-wrap"
                                    slot-scope="scope"
                                >
                                    <ImageList :data="scope.row.specImageUrl" />
                                    <div class="body">
                                        <div class="label">
                                            {{ scope.row.goodsName }}
                                        </div>
                                        <div class="spec">
                                            {{ scope.row.specDesc }}
                                        </div>
                                        <div
                                            class="badge-wrap"
                                        >
                                            <div
                                                class="badge"
                                                v-if="order.row.isPresellOrder"
                                            >
                                                预
                                            </div>
                                            <div
                                                class="badge"
                                                v-if="scope.row.goodsPremiumAmount"
                                            >
                                                保
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </el-table-column>
                            <el-table-column
                                prop="finalPrice"
                                label="单价"
                                width="100"
                            />
                            <el-table-column
                                prop="quantity"
                                label="数量"
                                width="100"
                            />
                            <el-table-column
                                label="订单金额"
                                width="100"
                            >
                                <el-popover
                                    trigger="hover"
                                >
                                    <el-table
                                        :show-header="false"
                                        :data="order.row.amountInfoList"
                                    >
                                        <el-table-column
                                            width="150px"
                                            property="label"
                                        />
                                        <el-table-column
                                            width="100px"
                                            property="value"
                                        />
                                    </el-table>
                                    <span
                                        slot="reference"
                                        class="tc-blue"
                                    >{{ order.row.payableAmount }}</span>
                                </el-popover>
                            </el-table-column>
                            <el-table-column
                                prop="orderStatusName"
                                label="订单状态"
                                width="100"
                            >
                                {{ order.row.orderStatusName }}
                            </el-table-column>
                            <el-table-column
                                label="物流信息"
                                width="300"
                            >
                                <el-popover
                                    trigger="hover"
                                >
                                    <div class="dp-fx fx-row fx-jc-sb fx-ai-c">
                                        <div class="w-50per">
                                            物流公司:{{ order.row.expressName }}
                                        </div>
                                        <div class="w-50per">
                                            物流单号:{{ order.row.trackingNumber }}
                                        </div>
                                    </div>
                                    <el-table
                                        :show-header="false"
                                        :data="order.row.trackingInfoList"
                                    >
                                        <el-table-column
                                            width="150px"
                                            property="ftime"
                                        />
                                        <el-table-column
                                            width="600px"
                                            property="context"
                                        />
                                    </el-table>
                                    <el-table
                                        :show-header="false"
                                        :data="order.row.clearanceTrackingInfoList"
                                    >
                                        <el-table-column
                                            width="150px"
                                            property="ftime"
                                        />
                                        <el-table-column
                                            width="600px"
                                            property="context"
                                        />
                                    </el-table>
                                    <el-table
                                        :show-header="false"
                                        :data="order.row.internationalTrackingInfoList"
                                    >
                                        <el-table-column
                                            width="150px"
                                            property="ftime"
                                        />
                                        <el-table-column
                                            width="600px"
                                            property="context"
                                        />
                                    </el-table>
                                    <div
                                        slot="reference"
                                        style="height: 100px"
                                        class="tc-blue"
                                    >
                                        <div v-if="order.row.trackingInfoList && order.row.trackingInfoList.length !== 0">
                                            <div
                                                class="dp-fx fx-jc-sb fx-ai-c"
                                                v-if="order.row.expressName"
                                            >
                                                <div class="w-50per">
                                                    物流公司:{{ order.row.expressName }}
                                                </div>
                                                <div class="ta-r w-50per">
                                                    物流单号:{{ order.row.trackingNumber }}
                                                </div>
                                            </div>
                                            <div>{{ order.row.trackingInfoList[0].context }}</div>
                                            <div>{{ order.row.trackingInfoList[0].ftime }}</div>
                                        </div>
                                        <div v-if="!order.row.trackingInfoList">
                                            暂无物流信息
                                        </div>
                                    </div>
                                </el-popover>
                            </el-table-column>
                            <el-table-column
                                label="同步状态"
                                width="90"
                                v-if="queryFormModel.orderStatus == '30-1' && userData.shop.isPushOrderErp"
                            >
                                {{ order.row.orderSyncErpStatusName }}
                                <div v-if="order.row.orderSyncErpStatus === 100">
                                    {{ order.row.orderSyncErpTime| moment('YYYY-MM-DD HH:mm:ss') }}
                                </div>
                            </el-table-column>
                        </el-table>
                        <div class="order-footer">
                            <div class="left">
                                <div class="address">
                                    收件信息：{{ order.row.deliveryConsignee }} <span class="tc-primary">{{ order.row.deliveryPhone }}</span> {{ order.row.deliveryRegionName }} {{ order.row.deliveryAddress }}
                                </div>
                                <div
                                    class="buyer-msg"
                                    title="买家留言"
                                >
                                    买家留言：{{ order.row.buyerMsg }}
                                </div>
                                <div
                                    class="buyer-msg"
                                    title="平台留言"
                                >
                                    平台留言：{{ order.row.orderComments }}
                                </div>
                            </div>
                            <div class="right">
                                <div class="inner">
                                    <!--<div
                                        v-if="order.row.isDistributor"
                                        class="distributor">
                                        代理商：
                                        {{ order.row.distributorName }} <span class="tc-primary">+{{ order.row.distributorCountryCode }}-{{ order.row.distributorTel }}</span>
                                    </div>-->
                                    <div
                                        v-if="order.row.extendFieldInfoList && order.row.extendFieldInfoList.length != 0"
                                        class="extra"
                                    >
                                        扩展信息：
                                        <el-popover
                                            trigger="hover"
                                        >
                                            <el-table :data="order.row.extendFieldInfoList">
                                                <el-table-column
                                                    width="150px"
                                                    property="label"
                                                    label="字段名称"
                                                />
                                                <el-table-column
                                                    width="200px"
                                                    property="value"
                                                    label="字段值"
                                                />
                                            </el-table>
                                            <span
                                                slot="reference"
                                                class="tc-blue"
                                            >{{ order.row.extendFieldInfoList[0].label }}:{{ order.row.extendFieldInfoList[0].value }}</span>
                                        </el-popover>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    width="100"
                    label="单价"
                />
                <el-table-column
                    width="100"
                    label="数量"
                />
                <el-table-column
                    width="100"
                    label="订单金额"
                />
                <el-table-column
                    width="100"
                    label="订单状态"
                />
                <el-table-column
                    width="300"
                    label="物流信息"
                />
                <el-table-column
                    width="90"
                    label="同步状态"
                    v-if="queryFormModel.orderStatus == '30-1' && userData.shop.isPushOrderErp"
                />
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'SaleOrder',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                orderSn: '',
                deliveryConsignee: '',
                deliveryPhone: '',
                orderStatus: '20',
                goodsName: '',
                orderType: '',
                paymentMode: '',
                shipMethod: '1',
                buyerLevelId: '',
                pickupDate: '',
                extendFieldInfo: '',
                orderTime: '',
                goodsTypeNE: '',
                goodsType: '1',
                orderSource: '',
                supplierName: '',
                distributorInfo: '',
                trackingNumber: '',
                estimateShippingTimeStr: '',
                isGoodsPremium: '',
                orderSyncErpStatus: '',
                buyerMobile: '',
            },
            // 订单发货
            shippingDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    expressId: '',
                    expressName: '',
                    expressCode: '',
                    trackingNumber: '',
                },
                // 表单校验规则
                formRules: {
                    expressId: {
                        required: true,
                        message: '请选择快递公司',
                    },
                    expressName: {
                        required: true,
                        message: '请输入快递公司名称',
                        trigger: 'blur',
                    },
                    trackingNumber: {
                        required: true,
                        message: '请输入快递单号',
                        trigger: 'blur',
                    },
                },
                // 订单数据
                orderData: {},
            },
            // 取消订单
            cancelDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    cancelDesc: '',
                },
                // 表单校验规则
                formRules: {
                    cancelDesc: {
                        required: true,
                        message: '请输入取消原因',
                        trigger: 'blur',
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            //等级列表
            buyerLevelList: [],
            //快递公司列表
            shopExpressList: [],
            //批量发货
            batchShippingOrderDialog: {
                // 对话框显示
                isVisible: false,
            },
            //预计发货时间列表
            estimateShippingTimeList: [],
            //转仓库处理
            stockHandleOrderDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    ids: '',
                    estimateShippingTime: '',
                },
                // 表单校验规则
                formRules: {
                    estimateShippingTime: {
                        required: true,
                        message: '请输入预计发货时间',
                        trigger: 'blur',
                    },
                },
            },
            //平台留言
            orderCommentsDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    orderComments: '',
                },
                // 表单校验规则
                formRules: {},
            },
            loading: '',
        };
    },
    computed: {
        tableColumn() {
            return this.$refs['table'].columns.length;
        },
    },
    methods: {
        /* -- 方法 -- */
        onSpanMethod() {
            return [1, this.tableColumn];
        },
        onGoodsSpanMethod({ columnIndex, rowIndex }) {
            // console.log({ row, column, rowIndex, columnIndex });
            if (columnIndex < 3) {
                return [1, 1];
            } else if (!rowIndex) {
                return [100, 1];
            } else {
                return [0, 0];
            }
        },
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.Order.orderList(queryData).then(json => {
                const res = json.data;
                res.data.forEach(item => {
                    const amountInfoList = [];
                    amountInfoList.push({
                        label: '商品总金额',
                        value: item.goodsAmount,
                    });
                    amountInfoList.push({
                        label: '运费',
                        value: item.freight,
                    });
                    amountInfoList.push({
                        label: '优惠券金额',
                        value: item.couponAmount,
                    });
                    amountInfoList.push({
                        label: '积分抵扣金额',
                        value: item.pointAmount,
                    });
                    amountInfoList.push({
                        label: '满额减免金额',
                        value: item.reduceAmount,
                    });
                    amountInfoList.push({
                        label: '总优惠金额',
                        value: item.totalDiscountAmount,
                    });
                    amountInfoList.push({
                        label: '保费金额',
                        value: item.goodsPremiumAmount,
                    });
                    amountInfoList.push({
                        label: '订单金额',
                        value: item.payableAmount,
                    });
                    item.amountInfoList = amountInfoList;
                });
                this.tableData = res.data;
                this.pagination = res.pagination;

                return Promise.resolve(json);
            });
        },
        initLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                this.buyerLevelList = res;
            });
        },
    },
    created() {
        this.initLevelList();
    },
};
</script>

<style lang="scss">
.order-table {
    td.el-table-column--selection {
        vertical-align: top;
    }

    td.cell-no-pa {
        &,
        > .cell {
            padding: 0;
        }
    }

    .table-ctrl {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .el-button + .el-button {
            margin: 0;
        }
    }

    .order-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: $padding $padding;
        background-color: $gray1;

        span + span {
            margin-left: $margin;
        }

        > .supplier {
            flex: 0 0 300px;
        }

        > .shipping-time {
            flex: 0 0 340px;
            text-align: right;
        }
    }

    .order-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding: $padding;

        > .left {
            flex: 0.6 0.6 1%;
        }

        > .right {
            flex: 0.4 0.4 1%;
            text-align: right;

            > .inner {
                text-align: left;
                display: inline-block;
            }
        }

        .buyer-msg {
            @include text-line(1);
        }
    }
}
</style>
<style lang="scss" scoped>
.goods-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    > .image-list-wrap {
        flex: 0 0 auto;
        margin-right: $margin;
    }

    > .body {
        flex: 1 1 1%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        .badge {
            display: inline-block;
            border-radius: 100em;
            background-color: $color-danger;
            color: #fff;
            line-height: 24px;
            min-width: 24px;
            text-align: center;
        }
    }
}
</style>
